<template>
  <div class="practiceNorms">
    <div class="practiceNorms-black">
      <div class="black-text" @click="handleBlack">
        <i class="el-icon-arrow-left"></i>
        待办委托列表
      </div>
    </div>
    <div class="practiceNorms-table">
      <div class="practiceNorms-table-title">
        <div class="table-title-item" style="width: 151px">流程</div>
        <div class="table-title-item" style="width: 95px">耗时</div>
        <div class="table-title-item" style="width: 216px">
          需客户知悉、配合事项
        </div>
        <div class="table-title-item" style="width: 186px">执业规范要求</div>
        <div class="table-title-item" style="width: 170px">
          标准成果、成果样板
        </div>
      </div>

      <div class="table-content-item">
        <div class="content-item" style="width: 151px">
          <span>*</span>研究客户需求
        </div>
        <div class="content-item" style="width: 95px; color: #999999">
          请填写
        </div>
        <div class="content-item" style="width: 216px">
          请上传...客户信息填写表
        </div>
        <div
          class="content-item"
          style="width: 186px; line-height: 20px; margin-top: 20px"
        >
          <div class="content-item-upload">请上传</div>
          <div class="content-item-text">《本流程节点规范要求》</div>
        </div>
        <div class="content-item" style="width: 170px"></div>
      </div>
    </div>

    <div class="practiceNorms-btn">点击提交</div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    // 返回
    handleBlack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.practiceNorms {
  height: 879px;
  &-black {
    height: 50px;
    display: flex;
    align-items: center;
    margin-left: 21px;
    border-bottom: 1px solid #f9f9f9;
    .black-text {
      cursor: pointer;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #333333;
      .el-icon-arrow-left {
        color: #333333;
      }
    }
  }
  &-table {
    width: 822px;
    border: 1px solid #eeeeee;
    border-radius: 5px;
    margin: 30px auto 0;
    &-title {
      display: flex;
      height: 53px;
      border-bottom: 1px solid #eeeeee;
      align-items: center;
      .table-title-item {
        text-align: center;
        font-size: 14px;
        height: 53px;
        line-height: 53px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #333333;
        border-right: 1px solid #eeeeee;
        &:last-of-type {
          border: none;
        }
      }
    }
    .table-content-item {
      height: 59px;
      border-bottom: 1px solid #eeeeee;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #333333;
      .content-item {
        height: 59px;
        line-height: 59px;
        text-align: center;
        border-right: 1px solid #eeeeee;
        span {
          color: #eb441e;
        }
        &:last-of-type {
          border: none;
        }
      }
      &:last-of-type {
        border: none;
      }
    }
  }
  &-btn {
    width: 97px;
    height: 41px;
    background: #4c8cff;
    border: 1px solid #ff6900;
    border-radius: 3px;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #ffffff;
    text-align: center;
    line-height: 41px;
    cursor: pointer;
    margin: 30px 0 0 114px;
  }
}
</style>
